import React, { useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import titleRobo from "./assets/title-robo.png";
import section1 from "./assets/section-1.png";
import section2 from "./assets/section-2.png";
import googlePlayBanner from "./assets/google-play-banner.png";
import appStoreBanner from "./assets/app-store-banner.png";

import featureLabel1 from "./assets/feature-label1.png";
import featureLabel2 from "./assets/feature-label2.png";
import featureLabel3 from "./assets/feature-label3.png";
import featureLabel4 from "./assets/feature-label4.png";
import featureLabel5 from "./assets/feature-label5.png";
import "./TopPage.scss";

const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=net.aidiary";
const APP_STORE_URL = "https://apps.apple.com/jp/app/id6736706558";

function TopPage() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const page = query.get("page");

  useEffect(() => {
    // /?page=pp の場合 /ppにリダイレクト
    if (page) {
      console.log("page", page);
      navigate("/" + page);
    }
  }, [navigate, page, query]);

  return (
    <div className="App">
      <div className="language-area">
        <a href="/">日本語</a>
        <p>/</p>
        <a href="/en">English</a>
        <p>/</p>
        <a href="/zh">中文</a>
      </div>
      <div className="title-area">
        <p className="sub-title">
          最短1分で書ける日記帳・交換日記・ロック付き!
        </p>
        <h1 className="title">AI日記</h1>

        <img src={titleRobo} className="title-image" alt="title-robo" />

        <div className="image-wrapper">
          <img
            src={googlePlayBanner}
            onClick={() => {
              window.open(GOOGLE_PLAY_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
          <img
            src={appStoreBanner}
            onClick={() => {
              window.open(APP_STORE_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
        </div>
      </div>

      <div className="paper" id="color-red">
        <TitleLabel
          label={featureLabel1}
          sub="あなたの毎日が輝き出す！"
          title="AIによる日記作成機能"
        />
        <img src={section1} className="section-image" alt="section1" />
        <div className="description-wrapper">
          <ul>
            <li>
              文才はなくても大丈夫！
              <br />
              プロ並みの日記でいつもの日常が輝き出します。
            </li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-blue">
        <TitleLabel
          label={featureLabel2}
          sub="交換日記も簡単！"
          title="お友達やみんなと日記をシェア"
        />
        <img src={section2} className="section-image" alt="section1" />
        <div className="description-wrapper">
          <ul>
            <li>素敵な日記が書けたらみんなにも見てもらおう！</li>
            <li>
              AIがプライベートな情報を自動でフィルター。
              <br />
              安心して公開できます。
            </li>
          </ul>
        </div>
      </div>
      <div className="paper" id="color-green">
        <TitleLabel
          label={featureLabel3}
          sub="機種変も安心！"
          title="サーバーに自動バックアップ"
        />
        <div className="description-wrapper">
          <ul>
            <li>
              日記はサーバーに保存されるので、スマホを無くしたり、機種変しても日記が消えることはありません。
              <span>※1</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="paper" id="color-cream">
        <TitleLabel
          label={featureLabel4}
          sub="あの日の日記もすぐ見つかる！"
          title="豊富な検索機能"
        />
        <div className="description-wrapper">
          <ul>
            <li>キーワード検索、タグ検索、日付検索など、多彩な　検索が可能</li>
            <li>みんなの人の日記もタグやキーワードで検索できます</li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-red">
        <TitleLabel label={featureLabel5} title="無料で使える！" />
        <div className="description-wrapper">
          <ul>
            <li>基本機能は全て無料で使えます。</li>
          </ul>
        </div>
      </div>

      <div className="paper" id="color-blue">
        <TitleLabel title="ご利用に際しての注意点" />
        <div className="description-wrapper">
          <ul>
            <li>
              本アプリは日記の生成にAIを使いますが、AIが出力する情報は必ずしも正しいとは限りません。必ず出力内容をご確認の上、日記の保存、公開を行ってください。
            </li>
            <li>
              お客様の日記は弊社のサーバーやプライバシーポリシーに記載された一部のサードパーティーサービスに送信、保存されることがあります。第三者から見て個人が特定できる情報や、機密情報は記載しないでください。
            </li>
            <li>
              必ず利用規約とプライバシーポリシーをご確認の上、同意してからご利用ください。
            </li>
          </ul>
        </div>
        <div className="annotation-wrapper">
          <ul>
            <li>
              ※1.サービス終了やメンテナンスなどの理由でサーバーが停止する可能性があります。ご了承ください。（シェア機能を使って定期的に外部サービスに日記をシェアしておくことをおすすめします）
            </li>
            {/* <li>
              ※2.2024/9/1現在は完全無料ですが、今後、有料版機能を提供する可能性はあります。
            </li> */}
          </ul>
        </div>
        <div className="tosppWrapper">
          <Link to="/tos">利用規約</Link>・
          <Link to="/privacy">プライバシーポリシー</Link>・
          <Link to="/deletedata">データの削除方法</Link>
        </div>
        <div className="image-wrapper">
          <img
            src={googlePlayBanner}
            onClick={() => {
              window.open(GOOGLE_PLAY_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
          <img
            src={appStoreBanner}
            onClick={() => {
              window.open(APP_STORE_URL, "_blank");
            }}
            className="google-playbanner-image"
            alt="google play"
          />
        </div>
      </div>
      <div className="paper" id="color-green">
        <TitleLabel title="お問合せ先" />

        <div className="description-wrapper">
          <a href="mailto:support@ai-daiary.net">support@ai-daiary.net</a>
        </div>
      </div>
    </div>
  );
}

type TitleLabelProps = {
  label?: string;
  sub?: string;
  title: string;
};
const TitleLabel = (props: TitleLabelProps) => {
  return (
    <div className="section-title-wrapper">
      {props.label && <img src={props.label} alt="label" />}
      <div className="section-title-right-wrapper">
        {props.sub && <p className="section-title-sub">{props.sub}</p>}
        <p className="title">{props.title}</p>
      </div>
    </div>
  );
};

export default TopPage;
