import React from "react";
import "./PrivacyPolicy.scss";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <div className="App">
      <div className="title-area">
        <h1 className="title">AI日記 プライバシーポリシー</h1>
      </div>

      <div className="content-area">
        <p>最終更新日: 2025/1/12</p>
        <br />
        <p>
          このプライバシーポリシーは、AI日記（以下、「本アプリ」）を提供するaidiary（以下、「開発者」）が、ユーザーから収集する情報、利用方法、およびユーザーの権利に関する方針を説明するものです。本アプリを利用することで、ユーザーはこのプライバシーポリシーに同意したものとみなされます。
        </p>

        <h2>1. 収集する情報</h2>
        <p>本アプリでは、以下の個人情報を収集します。</p>
        <ul>
          <li>メールアドレス</li>
          <li>生まれた年</li>
          <li>性別</li>
        </ul>
        <p>
          また、ユーザーが本アプリを利用して作成した日記内容は、サーバー上に保管されます。位置情報やデバイス情報は収集しません。
        </p>

        <h2>2. 情報の利用方法</h2>
        <p>開発者は、収集した情報を以下の目的で利用します。</p>
        <ul>
          <li>アカウントの管理</li>
          <li>運営からのお知らせメール送信</li>
          <li>AIによる日記の生成</li>
          <li>広告の表示</li>
          <li>アプリの利用状況に関する分析</li>
        </ul>

        <h2>3. 情報の共有</h2>
        <p>開発者は、以下の第三者とユーザーの情報を共有します。</p>
        <ul>
          <li>
            <strong>OpenAI社</strong>: AIによる日記生成のため
          </li>
          <li>
            <strong>Google社</strong>: 広告表示およびアカウント管理のため
          </li>
        </ul>
        <p>
          広告を表示する際、ユーザーの行動データは広告主と共有され、ターゲティング広告に利用される場合があります。
        </p>

        <h2>4. ユーザーの権利</h2>
        <p>
          ユーザーは、自身の情報の閲覧、修正、削除をリクエストする権利を有しています。これらのリクエストは、本アプリ内の設定またはお問い合わせフォームを通じて行うことができます。
        </p>

        <h2>5. 未成年者のプライバシー</h2>
        <p>
          本アプリには年齢制限はありませんが、18歳未満のユーザーは、必ず保護者の同意を得た上で本アプリを利用してください。開発者は、未成年者が保護者の同意を得ていない場合においては、本アプリの利用を禁止します。
        </p>

        <h2>6. データの保存期間</h2>
        <p>
          開発者は、ユーザーが本アプリ内の操作で情報を削除するまで、収集した情報を保存します。ユーザーがアカウントを削除した場合、開発者は全ての関連データを完全に削除します。
        </p>
        <h2>7. データの転送について</h2>
        <p>
          開発者は、サービスの提供および運営のため、ユーザーの個人データを日本および米国に転送する場合があります。
          これには、開発者のサーバー、サービス提供者（例：Amazon Web
          Services、Firebase、AdMob、OpenAI）を含む第三者が所在する国への
          データ転送が含まれます。これらの国では、ユーザーが居住する国（日本）と同等のデータ保護法が適用されない場合があります。
        </p>

        <h3>データ転送の理由</h3>
        <ul>
          <li>
            クラウドストレージとサービスの利用：Amazon Web
            Services（AWS）やFirebaseを使用してデータを安全に保存および管理。
          </li>
          <li>広告配信：AdMobを使用したターゲティング広告の提供。</li>
          <li>日記の生成：OpenAI社の生成AIの利用</li>
          <li>
            サービスの運営：アプリの継続的な機能提供およびユーザーエクスペリエンス向上。
          </li>
        </ul>

        <h3>データ保護措置</h3>
        <ul>
          <li>
            <strong>十分性認定：</strong>
            日本は欧州委員会による十分性認定を受けており、データ保護の水準が適切であるとされています。
            そのため、日本へのデータ転送は適用法規に準拠しています。
          </li>
          <li>
            <strong>技術的および組織的な対策：</strong>
            AWSやFirebaseなどのサービスでは、データ暗号化やアクセス制限などのセキュリティ措置が実施されています。
          </li>
        </ul>

        <h3>ユーザーの権利</h3>
        <ul>
          <li>
            自身のデータが転送される国（米国や日本）についての詳細な情報を要求する権利。
          </li>
          <li>開発者が適用しているデータ保護措置の詳細を要求する権利。</li>
        </ul>

        <h2>8. プライバシーポリシーの変更</h2>
        <p>
          開発者は、必要に応じてこのプライバシーポリシーを変更することがあります。変更があった場合、ユーザーは改定後のポリシーに同意したものとみなされます。重要な変更があった場合は、本アプリ内で通知します。
        </p>

        <h2>9. お問い合わせ先</h2>
        <a href="mailto:support@ai-daiary.net">support@ai-daiary.net</a>
      </div>
      <Link to="/" className="back-link">
        戻る
      </Link>
    </div>
  );
}
export default PrivacyPolicy;
